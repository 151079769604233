// in src/App.js
import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { BoxCreate, BoxEdit, BoxList } from './Boxes';
import { CycleEdit, CycleList } from './Cycles';
import { Login } from './Login';
import { RuleCreate } from './Rules';
import { NoteCreate } from './Notes';
import { ShipmentSummary } from './Shipment';
import { IncompleteCycleList, IncompleteCycleEdit } from './IncompleteCycles';
import { ShippedBoxes } from './ShippedBoxes';
import { AdhocOrdersCreate, AdhocOrdersList } from './AdhocOrders';
import { AdhocWowAddonsCreate, AdhocWowAddonsList } from './AdhocWowAddons';
import { BoxSplitScopeCreate, BoxSplitScopeList } from './BoxSplitScopes';
import { UploadRulesCreate, UploadRulesList } from './UploadRules';
import { StrapiBoxesCreate, StrapiBoxesList } from './StrapiBoxes';
import { StrapiAddonsCreate, StrapiAddonsList } from './MonthlyAddons';
import { DryrunResultsList } from './DryrunResults';
import { DryrunForm, dryrunList } from './DryrunForm';
import { BoxSync } from './BoxSync';

import {
  ProductsCreate,
  ProductsList,
  ProductsUpdate,
  ProductsListEmpty,
  ProductsImagesBulkUpload,
} from './Products';
import { Download } from './Download';
import { authProvider, dataProvider } from './providers';

const App = () => (
  <Admin
    loginPage={Login}
    authProvider={authProvider}
    dataProvider={dataProvider}
  >
    <Resource name="shipment" list={ShipmentSummary} />
    <Resource name="shipped_boxes" list={ShippedBoxes} />
    <Resource name="cycle" list={CycleList} edit={CycleEdit} />
    <Resource
      name="box_split_scope"
      list={BoxSplitScopeList}
      create={BoxSplitScopeCreate}
    />
    <Resource
      name="incomplete_cycle"
      list={IncompleteCycleList}
      edit={IncompleteCycleEdit}
      filters={['id']}
    />
    <Resource name="box" list={BoxList} create={BoxCreate} edit={BoxEdit} />
    <Resource
      name="adhoc_orders"
      list={AdhocOrdersList}
      create={AdhocOrdersCreate}
    />
    <Resource name="rules" list={UploadRulesList} create={UploadRulesCreate} />
    <Resource
      name="strapi_boxes"
      list={StrapiBoxesList}
      create={StrapiBoxesCreate}
    />
    <Resource
      name="monthly_addons"
      list={StrapiAddonsList}
      create={StrapiAddonsCreate}
    />
    <Resource name="rule" create={RuleCreate} />
    <Resource name="box_sku" />
    <Resource name="note" create={NoteCreate} />
    <Resource name="dryrun_results" list={DryrunResultsList} />
    <Resource name="dryrun" list={dryrunList} create={DryrunForm} />
    <Resource name="box_sync" list={BoxSync} />
    <Resource
      name="create_strapi_products"
      list={ProductsListEmpty}
      create={ProductsCreate}
    />
    <Resource
      name="upload_missing_images"
      edit={ProductsUpdate}
      create={ProductsImagesBulkUpload}
      list={ProductsList}
    />
    <Resource name="download" list={Download} />
    <Resource
      name="adhoc_wow_addons"
      list={AdhocWowAddonsList}
      create={AdhocWowAddonsCreate}
    />
  </Admin>
);

export default App;
