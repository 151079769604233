import {
  DataProvider,
  GetListParams,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetListResult,
  UpdateParams,
  UpdateResult,
  UpdateManyParams,
  UpdateManyResult,
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  DeleteManyParams,
  DeleteManyResult,
} from 'ra-core';

import ApiClient, { MemberWowAddon } from './api-client';
import { subDays, eachDayOfInterval, format } from 'date-fns/fp';
import { getDate, subMonths } from 'date-fns';
import { isCycleRef } from '@by-goodiebox/subscription-platform-entities';
/*
  Request
  Method	Usage	Parameters format
  getList	Search for resources	{ pagination: { page: {int} , perPage: {int} }, sort: { field: {string}, order: {string} }, filter: {Object} }
  getOne	Read a single resource, by id	{ id: {mixed} }
  getMany	Read a list of resource, by ids	{ ids: {mixed[]} }
  getManyReference	Read a list of resources related to another one	{ target: {string}, id: {mixed}, pagination: { page: {int} , perPage: {int} }, sort: { field: {string}, order: {string} }, filter: {Object} }
  create	Create a single resource	{ data: {Object} }
  update	Update a single resource	{ id: {mixed}, data: {Object}, previousData: {Object} }
  updateMany	Update multiple resources	{ ids: {mixed[]}, data: {Object} }
  delete	Delete a single resource	{ id: {mixed}, previousData: {Object} }
  deleteMany	Delete multiple resources	{ ids: {mixed[]} }

  Response
  Method	Response format
  getList	{ data: {Record[]}, total: {int}, validUntil?: {Date} }
  getOne	{ data: {Record}, validUntil?: {Date} }
  getMany	{ data: {Record[]}, validUntil?: {Date} }
  getManyReference	{ data: {Record[]}, total: {int} }
  create	{ data: {Record} }
  update	{ data: {Record} }
  updateMany	{ data: {mixed[]} } The ids which have been updated
  delete	{ data: {Record} } The record that has been deleted
  deleteMany	{ data: {mixed[]} } The ids of the deleted records (optional)
  A {Record} is an object literal with at least an id property, e.g. { id: 123, title: "hello, world" }.

 */
export default class CycleConfigDataProvider implements DataProvider {
  apiClient: ApiClient;

  constructor(apiHost: string) {
    this.apiClient = new ApiClient(apiHost);
  }

  async getList(
    resource: string,
    {
      pagination: { page, perPage },
      sort: { field, order },
      filter,
    }: GetListParams,
  ): Promise<GetListResult> {
    console.log(field, order, filter, this);

    const processData = <T extends Record<string, any>>(data: T[]) => {
      return data
        .filter((data: T) => this.filterData(data, filter))
        .sort((data1: T, data2: T) =>
          this.sortData(data1, data2, field, order),
        );
    };

    const paginateData = <T extends Record<string, any>>(data: T[]) =>
      data.slice((page - 1) * perPage, page * perPage);

    const error = new Error(
      `Get List operation not supported for resource: '${resource}'.`,
    );

    switch (resource) {
      // Needed to return something, in order to get navigation list tab
      case 'create_strapi_products':
        return {
          data: [],
          total: 0,
        };
      case 'upload_missing_images':
        const strapiProductsData = await this.apiClient.fetchStrapiProducts();
        const processStrapiProductsData = processData(strapiProductsData);
        return {
          data: paginateData(processStrapiProductsData),
          total: processStrapiProductsData.length,
        };
      case 'cycle':
        const cycleData = await this.apiClient.fetchCycles();
        const processedCycleData = processData(cycleData);
        return {
          data: paginateData(processedCycleData),
          total: processedCycleData.length,
        };
      case 'incomplete_cycle':
        const incompleteCycleData = await this.apiClient.fetchIncompleteCycles();
        const processedIncompleteCycleData = processData(incompleteCycleData);
        return {
          data: paginateData(processedIncompleteCycleData),
          total: processedIncompleteCycleData.length,
        };
      case 'rule':
        return { data: [], total: 0 };
      case 'dryrun':
        return { data: [], total: 0 };
      case 'box':
        const boxData = await this.apiClient.fetchBoxes();
        const processedBoxData = processData(boxData);
        return {
          data: paginateData(processedBoxData),
          total: processedBoxData.length,
        };
      case 'box_sku':
        const skuData = await this.apiClient.fetchBoxSkus();
        return {
          data: paginateData(skuData),
          total: skuData.length,
        };
      case 'note':
        throw error;
      case 'box_split_scope':
        const boxSplitScopes = await this.apiClient.fetchBasicRuleEntryScopes();
        return {
          data: paginateData(
            boxSplitScopes.map((scope: any) => ({
              id: scope.name,
              name: scope.description,
            })),
          ),
          total: boxSplitScopes.length,
        };
      case 'shipment':
        const dateRange = eachDayOfInterval({
          start: subDays(32, new Date()),
          end: subDays(0, new Date()),
        });
        const shipmentSummaries = await Promise.all(
          dateRange.map(async (date) => {
            const summary = await this.apiClient.fetchShipmentSummary(date);
            const total = Object.values(summary).reduce<number>(
              (tot, val) => tot + (val || 0),
              0,
            );
            return {
              date,
              id: date.getTime(),
              summary,
              total,
            };
          }),
        );
        return {
          data: shipmentSummaries.reverse(),
          total: 7,
        };
      case 'shipped_boxes':
        const datesRange = eachDayOfInterval({
          start: subDays(7, new Date()),
          end: subDays(1, new Date()),
        });

        // Create an array storing box shipment count for 7 days
        const baseDateSet: number[] = [0, 0, 0, 0, 0, 0, 0];

        // Set of sku row data, with unique sku and past week's box shipment counts
        const skuRows: Record<string, number[]> = {};

        await Promise.all(
          datesRange.map(async (date, index) => {
            const boxShipped = await this.apiClient.fetchBoxShippedSummary(
              date,
            );
            Object.entries(boxShipped).forEach(([sku, count]) => {
              if (!skuRows[sku]) {
                // create new sku row data
                const pastWeek = [...baseDateSet];
                pastWeek[index] = count;
                skuRows[sku] = pastWeek;
              } else {
                // update existing sku row data
                skuRows[sku][index] = count;
              }
            });
          }),
        );
        const data = Object.keys(skuRows).map((sku, index) => {
          return {
            id: index,
            sku,
            dailyCounts: skuRows[sku],
            total: skuRows[sku].reduce((prev, cur) => prev + cur, 0),
          };
        });
        return {
          data,
          total: data.length,
        };
      case 'adhoc_orders':
        const adhocOrders = await this.apiClient.fetchAdhocOrders(1000);
        const processedAdHocData = processData(adhocOrders);
        return {
          data: paginateData(processedAdHocData),
          total: adhocOrders.length,
        };
      case 'adhoc_wow_addons':
        const _filter = filter.cycleRef;
        const adhocAddons = await this.apiClient.fetchAdhocAddons(
          isCycleRef(_filter) ? _filter : 'DK-Feb-2023', // Just a default cycle ref
        );

        // The adhocAddons records don't have id field - we use the addon's wowProduct's rowId as id
        const adhocAddonsWithId = adhocAddons
          .map((addon: MemberWowAddon) => {
            return addon.wowProducts.map((w) => {
              return { id: w.rowId, ...addon };
            });
          })
          .flat();
        const processedAdhocAddons = processData(adhocAddonsWithId);
        return {
          data: paginateData(processedAdhocAddons),
          total: processedAdhocAddons.length,
        };

      case 'rules':
        const rules = await this.apiClient.fetchBasicRuleEntries(1000);
        const processedRulesData = processData(rules);
        return {
          data: paginateData(processedRulesData),
          total: rules.length,
        };
      case 'strapi_boxes':
        return {
          data: [],
          total: 0,
        };
      case 'monthly_addons':
        return {
          data: [],
          total: 0,
        };
      case 'dryrun_results':
        const dryrunResults = await this.apiClient.fetchDryrunResults();
        const processedDryrunResults = processData(dryrunResults);
        return {
          data: paginateData(processedDryrunResults),
          total: dryrunResults.length,
        };
      case 'download':
        return { data: [], total: 0 };
      default:
        throw new Error(`Unknown resource: '${resource}'`);
    }
  }

  filterData(data: any, filter: any) {
    if (filter && filter.cycleMonth) {
      const value = filter.cycleMonth;
      const paymentCaptureDate = new Date(data.paymentCapture);
      const isPaymentCaptureDateAbove15th = getDate(paymentCaptureDate) > 15;
      let target;
      if (isPaymentCaptureDateAbove15th) {
        target = data.paymentCapture;
      } else {
        target = format('yyyy-MM-dd', subMonths(paymentCaptureDate, 1));
      }
      return target.startsWith(value);
    } else if (filter && filter.boxSku) {
      const value = filter.boxSku.toLowerCase();
      return data.boxSku.toLowerCase().includes(value);
    } else if (filter && filter.q) {
      return Object.values(data).some(
        (v: any) => v.includes && v.includes(filter.q),
      );
    } else if (filter && filter.order_id) {
      const value = filter.order_id.toLowerCase();
      return data.order_id.toLowerCase().includes(value);
    } else if (filter && filter.cycleRef) {
      const value = filter.cycleRef.toLowerCase();
      return data.cycleRef.toLowerCase().includes(value);
    } else if (filter && filter.boxSku) {
      const value = filter.boxSku.toLowerCase();
      return data.boxSku.toLowerCase().includes(value);
    } else if (filter && filter.scope) {
      const value = filter.scope.toLowerCase();
      return data.scope.toLowerCase().includes(value);
    } else if (filter && filter.SKU) {
      const value = filter.SKU.toLowerCase();
      return data.SKU.toLowerCase().includes(value);
    } else if (filter && filter.sku) {
      const value = filter.sku.toLowerCase();
      return data.sku.toLowerCase().includes(value);
    } else if (filter && filter.flow) {
      const value = filter.flow.toLowerCase();
      return data.flow.toLowerCase().includes(value);
    } else if (filter && filter.memberId) {
      const value = filter.memberId.toLowerCase();
      return data.memberId.toLowerCase().includes(value);
    } else {
      return true;
    }
  }

  sortData(data1: any, data2: any, field: string, order: string): number {
    const val1 = data1[field];
    const val2 = data2[field];
    const modifier = order === 'DESC' ? -1 : 1;

    if (val1 < val2) {
      return modifier * -1;
    } else if (val1 > val2) {
      return modifier * 1;
    } else {
      return 0;
    }
  }

  async getOne(resource: string, { id }: GetOneParams): Promise<GetOneResult> {
    const error = new Error(
      `Get One operation not supported for resource: '${resource}'.`,
    );
    switch (resource) {
      case 'cycle':
        const cycle = await this.apiClient.fetchCycle(id);
        return { data: cycle };
      case 'incomplete_cycle':
        const incompleteCycles = await this.apiClient.fetchIncompleteCycles();
        return { data: incompleteCycles[id as any] };
      case 'box':
        const boxData = await this.apiClient.fetchBoxes();
        const box = boxData.find(({ boxSku }) => id === boxSku);
        if (!box) {
          throw new Error(`Box '${id}' not found`);
        }
        return { data: { ...box, id: box.boxSku } };
      case 'rule':
      case 'note':
      case 'box_split_scope':
      case 'box_sku':
        throw error;
      default:
        throw new Error(`Unknown resource: '${resource}'`);
    }
  }

  async getMany(
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult> {
    const error = new Error(
      `Get Many operation not supported for resource: '${resource}'.`,
    );
    switch (resource) {
      case 'cycle':
        const cycles = await this.apiClient.fetchCycles();
        const filteredCycles = cycles.filter(({ id }) => ids.includes(id));
        return { data: filteredCycles };
      case 'rule':
      case 'box':
      case 'note':
      case 'box_split_scope':
      case 'incomplete_cycle':
      case 'box_sku':
        throw error;
      default:
        throw new Error(`Unknown resource: '${resource}'`);
    }
  }

  async getManyReference(
    resource: string,
    {
      target,
      id,
      pagination: { page, perPage },
      sort: { field, order },
      filter,
    }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult> {
    const error = new Error(
      `Get Many with Reference operation not supported for resource: '${resource}'.`,
    );
    switch (resource) {
      case 'rule':
        if (target !== 'cycle_id') {
          throw new Error(`Unknown target for rule: '${target}'`);
        }
        const rules = await this.apiClient.fetchRules(id);
        return { data: rules, total: rules.length };
      case 'note':
        if (target !== 'cycle_id') {
          throw new Error(`Unknown target for note: '${target}'`);
        }
        const notes = await this.apiClient.fetchNotes(id);
        return { data: notes, total: notes.length };
      case 'box':
      case 'cycle':
      case 'incomplete_cycle':
      case 'box_sku':
        throw error;
      default:
        throw new Error(`Unknown resource: '${resource}'`);
    }
  }

  async update(
    resource: string,
    { id, data, previousData }: UpdateParams,
  ): Promise<UpdateResult> {
    const error = new Error(
      `Update operation not supported for resource: '${resource}'.`,
    );
    switch (resource) {
      case 'cycle':
        await this.apiClient.updateCycle(id, data);
        return { data };
      case 'incomplete_cycle':
        // Updating an incomplete cycle is actually creating a new cycle.
        await this.apiClient.createCycle(data);
        return { data };
      case 'box':
        await this.apiClient.createBox(data);
        return { data };
      case 'rule':
      case 'note':
      case 'box_split_scope':
      case 'box_sku':
        throw error;
      default:
        throw new Error(`Unknown resource: '${resource}'`);
    }
  }

  async updateMany(
    resource: string,
    params: UpdateManyParams,
  ): Promise<UpdateManyResult> {
    const error = new Error(
      `Update Many operation not supported for resource: '${resource}'.`,
    );
    switch (resource) {
      case 'rule':
      case 'box':
      case 'note':
      case 'box_split_scope':
      case 'cycle':
      case 'incomplete_cycle':
      case 'box_sku':
        throw error;
      default:
        throw new Error(`Unknown resource: '${resource}'`);
    }
  }

  async create(
    resource: string,
    { data }: CreateParams,
  ): Promise<CreateResult> {
    const error = new Error(
      `Create operation not supported for resource: '${resource}'.`,
    );
    switch (resource) {
      case 'create_strapi_products':
        const fileTitleProducts: string = data.files.title;
        const rawFileProducts: File = data.files.rawFile;
        await this.apiClient.createStrapiProducts(
          fileTitleProducts,
          rawFileProducts,
        );
        return { data };
      case 'upload_missing_images':
        await this.apiClient.uploadImages(data);
        return { data };
      case 'rule':
        await this.apiClient.createRule(data);
        return { data };
      case 'box':
        const fileTitle: string = data.files.title;
        const rawFile: File = data.files.rawFile;
        await this.apiClient.boxesUpload(fileTitle, rawFile);
        return { data };
      case 'note':
        await this.apiClient.createNote(data);
        return { data };
      case 'box_split_scope':
        const { name } = data;
        if (name.split(' ').length > 1)
          throw new Error(`Spacing not allowed in scope name`);
        await this.apiClient.addBasicRuleEntryScope(data);
        return { data };
      case 'adhoc_orders':
        await this.apiClient.bulkUploadAdhocOrders(data);
        return { data };
      case 'adhoc_wow_addons':
        await this.apiClient.uploadWowAddons(data);
        return { data };
      case 'rules':
        await this.apiClient.bulkUploadRules(data);
        return { data };
      case 'cycle':
      case 'incomplete_cycle':
      case 'box_sku':
        throw error;
      case 'strapi_boxes':
        await this.apiClient.bulkUploadStrapiBoxes(data);
        return { data };
      case 'monthly_addons':
        await this.apiClient.uploadMonthlyAddons(data);
        return { data };
      case 'dryrun':
        await this.apiClient.createDryrun(data);
        return { data };
      default:
        throw new Error(`Unknown resource: '${resource}'`);
    }
  }

  async delete(
    resource: string,
    { id, previousData }: DeleteParams,
  ): Promise<DeleteResult> {
    const error = new Error(
      `Delete operation not supported for resource: '${resource}'.`,
    );
    switch (resource) {
      case 'cycle':
      case 'incomplete_cycle':
      case 'box_sku':
        throw error;
      case 'box':
        await this.apiClient.deleteBox(id);
        return { data: { id } };
      case 'rule':
        const cycleId = previousData.cycleId as number;
        await this.apiClient.deleteRule(cycleId, id);
        return { data: { id } };
      case 'note':
        await this.apiClient.deleteNote(id);
        return { data: { id } };
      case 'adhoc_orders':
        await this.apiClient.deleteAdhocOrder(id);
        return { data: { id } };
      case 'adhoc_wow_addons':
      default:
        throw new Error(`Unknown resource: '${resource}'`);
    }
  }

  async deleteMany(
    resource: string,
    params: DeleteManyParams,
  ): Promise<DeleteManyResult> {
    const error = new Error(
      `Delete many operation not supported for resource: '${resource}'.`,
    );
    switch (resource) {
      case 'rule':
      case 'box':
      case 'note':
      case 'cycle':
      case 'incomplete_cycle':
      case 'box_sku':
        throw error;
      case 'adhoc_orders':
        const ids = params.ids;
        await this.apiClient.deleteManyAdhocOrder(ids);
        return { data: ids };
      case 'adhoc_wow_addons':
        const wowIds = params.ids;
        await this.apiClient.deleteManyAddons(wowIds);
        return { data: wowIds };
      default:
        throw new Error(`Unknown resource: '${resource}'`);
    }
  }
}
