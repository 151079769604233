import * as React from 'react';
import { dataProvider } from './providers';
import { useState } from 'react';
import { Loading } from 'react-admin';
import { StrapiScopeUploadResult } from './api-client';
import { useNotify } from 'react-admin';

const buttonStyle = {
  backgroundColor: '#EA4C89',
  borderRadius: '8px',
  borderWidth: '0',
  color: 'white',
  cursor: 'pointer',
  display: 'inline-block',
  margin: '12px 12px',
  padding: '10px 12px',
  transition: 'all 200ms',
  verticalAlign: 'baseline',
  width: '200px',
};
const containerStyle = {
  backgroundColor: 'lightblue',
  padding: '20px',
  display: 'flex',
  FlexDirection: 'row',
  alignItems: 'center',
  OverflowX: 'auto',
};
const filterFormStyle = {
  marginTop: '20px',
  border: '1px solid gray',
  padding: '20px',
};
const formContainerStyle = {
  backgroundColor: 'lightblue',
  padding: '20px',
  display: 'flex',
  FlexDirection: 'column',
  alignItems: 'center',
};

const selectStyle = {
  padding: '8px',
  fontSize: '16px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  marginBottom: '10px',
};

const inputStyle = {
  padding: '8px',
  fontSize: '16px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  marginBottom: '10px',
};

const formButtonStyle = {
  backgroundColor: 'blue',
  color: 'white',
  padding: '10px',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '16px',
};

const disabledButtonStyle = {
  backgroundColor: 'gray',
  color: 'black',
  padding: '10px',
  borderRadius: '4px',
  cursor: 'not-allowed',
  fontSize: '16px',
};

const responseContainerStyle = {
  marginTop: '20px',
  border: '1px solid gray',
  padding: '20px',
};
const FilterForm = () => {
  const [formData, setFormData] = useState({ year: '', month: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notify = useNotify();

  const [
    apiResponse,
    setApiResponse,
  ] = useState<StrapiScopeUploadResult | null>(null);

  const months = [
    { name: 'January', value: '1' },
    { name: 'February', value: '2' },
    { name: 'March', value: '3' },
    { name: 'April', value: '4' },
    { name: 'May', value: '5' },
    { name: 'June', value: '6' },
    { name: 'July', value: '7' },
    { name: 'August', value: '8' },
    { name: 'September', value: '9' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' },
  ];
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const { year, month } = formData;
    dataProvider.apiClient
      .uploadStrapiScopes({ year: year, month: month })
      .then((response) => {
        setIsLoading(false);
        setApiResponse(response);
      })
      .catch((e) => {
        setIsLoading(false);
        notify(e.message, { type: 'error' });
      });
  };

  return (
    <div>
      <h3>Create scope and rules based on Strapi boxes</h3>
      <form style={formContainerStyle} onSubmit={handleFormSubmit}>
        <label>
          Month:
          <select
            name="month"
            value={formData.month}
            onChange={handleInputChange}
            style={selectStyle}
            required
          >
            <option value="">Select a month</option>
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Year:
          <input
            type="text"
            name="year"
            value={formData.year}
            onChange={handleInputChange}
            style={inputStyle}
            required
          />
        </label>
        <button
          style={isLoading ? disabledButtonStyle : formButtonStyle}
          type="submit"
          disabled={isLoading}
        >
          Submit
        </button>
      </form>
      {isLoading ? (
        <Loading />
      ) : (
        apiResponse && (
          <div style={responseContainerStyle}>
            <h3>API Response:</h3>
            <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
          </div>
        )
      )}
    </div>
  );
};

export const BoxSync = () => {
  const [placeHolderText, setPlaceholderText] = React.useState<string>();

  const sync = () => {
    setPlaceholderText(`Initiating Sync`);
    dataProvider.apiClient
      .syncStrapiBoxes()
      .then(() => {
        setPlaceholderText('Sync complete');
      })
      .catch((err) => {
        setPlaceholderText(JSON.stringify(err, null, 2));
      });
  };
  return (
    <>
      <div style={containerStyle}>
        <h3>
          This button will fetch boxes from Strapi and upload them to Logistics
          Admin
        </h3>
        <button style={buttonStyle} onClick={sync}>
          Sync Strapi boxes
        </button>
        {placeHolderText && <h5>{placeHolderText}</h5>}
      </div>
      <div style={filterFormStyle}>
        <FilterForm />
      </div>
    </>
  );
};
