import * as React from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
} from 'react-admin';
import CycleLabel from './CycleLabel';
import { CyclesFilter } from './Cycles';
import { isAfter, parseISO, addMonths, isBefore } from 'date-fns';
import {
  addDays,
  endOfMonth,
  getDate,
  eachWeekendOfInterval,
  formatISOWithOptions,
} from 'date-fns/fp';

const required = (message = 'Required') => (value: any) => {
  return value ? undefined : message;
};

/**
 * This has been copied & adapted from the delivery-service:
 * https://github.com/by-goodiebox/delivery-service/blob/7d733de50ea5405ad46e0bbb53b1b1813aa0d241/src/services/cycles.ts#L131
 *
 * If the logic changes in delivery service, we should update it here!
 */
const isValidManifestCreationDate = ({
  paymentCapture,
  manifestCreation,
}: {
  paymentCapture: Date;
  manifestCreation: Date;
}): boolean => {
  const adjustedPaymentCapture =
    getDate(paymentCapture) >= 28 ? endOfMonth(paymentCapture) : paymentCapture;
  const offsetDate = addDays(1, adjustedPaymentCapture);
  const extraOffset = eachWeekendOfInterval({
    start: adjustedPaymentCapture,
    end: offsetDate,
  }).length;
  const adjustedOffsetDate = addDays(extraOffset, offsetDate);
  const earliestManifestCreation = adjustedOffsetDate;

  const fmt = formatISOWithOptions({ representation: 'date' });

  return fmt(earliestManifestCreation) <= fmt(manifestCreation);
};

const manifestValidation = (value: any, allValues: any) => {
  const parsedCaptureDate = parseISO(allValues.paymentCapture);
  const parsedManifestDate = parseISO(value);

  if (!parsedManifestDate) {
    return 'The Manifest Date is required';
  }
  if (
    !isValidManifestCreationDate({
      manifestCreation: parsedManifestDate,
      paymentCapture: parsedCaptureDate,
    })
  ) {
    return `Must be at least 1 weekday after the ${
      getDate(parsedCaptureDate) >= 28
        ? 'end of the month in which the capture date falls'
        : 'capture date'
    }`;
  }
  return;
};

const boxReleaseValidation = (value: any, allValues: any) => {
  const parsedCaptureDate = parseISO(allValues.paymentCapture);
  const parsedManifestDate = parseISO(allValues.manifestCreation);
  const parsedReleaseDate = parseISO(value);
  const limitBoxReleaseDate = addMonths(parsedCaptureDate, 1);
  if (!parsedReleaseDate) {
    return 'The Box Release Date is required';
  }
  if (!isAfter(parsedReleaseDate, parsedManifestDate)) {
    return `Box Release must happen after manifest creation date`;
  }
  if (!isBefore(parsedReleaseDate, limitBoxReleaseDate)) {
    return `Box Release must happen on a date within the next 30 days after payment capture`;
  }
  return;
};

export const validateManifestCreation = [required(), manifestValidation];
export const validateBoxRelease = [required(), boxReleaseValidation];
export const IncompleteCycleEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="/cycle">
        <TextInput disabled source="id" />
        <TextInput disabled source="country" />
        <TextInput disabled source="productPlanSku" />
        <DateInput disabled source="paymentCapture" />
        <DateInput
          source="manifestCreation"
          required
          validate={validateManifestCreation}
        />
        <DateInput source="boxRelease" required validate={validateBoxRelease} />
      </SimpleForm>
    </Edit>
  );
};

export const IncompleteCycleList = (props: any) => (
  <List {...props} perPage={50} filters={<CyclesFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <CycleLabel source="Cycle Label" {...props} />
      <TextField source="country" />
      <TextField source="productPlanSku" />
      <DateField source="paymentCapture" />
    </Datagrid>
  </List>
);
