import React from "react";
import {
  List,
  Datagrid,
  NumberField,
  TextField,
  Filter,
  TextInput,
} from 'react-admin';

const DryrunResultsFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search by flow" source="flow" alwaysOn />
      <TextInput label="Search by cycle ref" source="cycleRef" alwaysOn />
      <TextInput label="Search by sku" source="sku" alwaysOn />
    </Filter>
  );
};

export const DryrunResultsList = (props: any) => {
  return (
    <List {...props} filters={<DryrunResultsFilter />}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="flow" />
        <TextField source="cycleRef" />
        <TextField source="sku" />
        <NumberField source="count" />
        <TextField source="error" />
      </Datagrid>
    </List>
  );
};
