import * as React from 'react';
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  Datagrid,
  TextField,
  List,
  DateField,
  Filter,
  TextInput,
} from 'react-admin';

export const AdhocOrdersCreate = (props: any) => {
  const redirect = '/adhoc_orders';
  return (
    <Create {...props}>
      <SimpleForm submitOnEnter={false} redirect={redirect}>
        <FileInput
          source="files"
          label="Related files"
          accept=".xlsx,.xls"
          name="files"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

const OrderFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search by Order ID" source="order_id" alwaysOn />
    </Filter>
  );
};

export const AdhocOrdersList = (props: any) => {
  return (
    <List {...props} filters={<OrderFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="order_id" />
        <TextField source="sku" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="address1" />
        <TextField source="address2" />
        <TextField source="zipcode" />
        <TextField source="city" />
        <TextField source="telephone" />
        <TextField source="city" />
        <DateField source="created_at" />
        <DateField source="submittedAt" />
        <DateField source="acceptedAt" />
        <DateField source="cancelledAt" />
        <DateField source="packedAt" />
      </Datagrid>
    </List>
  );
};
