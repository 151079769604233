import React from 'react';
import { FunctionField } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { CountryCode } from '@by-goodiebox/subscription-platform-entities';
import { addMonths, format } from 'date-fns';
import { getDate } from 'date-fns/fp';

const useStyles = makeStyles({
  CRUSH: { backgroundColor: '#B2DFDB' },
  GOODIEBOX: { backgroundColor: '#BBDEFB' },
});

const getFlagPath = (country: CountryCode): string => `flags/${country}.svg`;

const CycleLabel = ({ ...props }: any) => {
  const classes: any = useStyles();
  const paymentDate = new Date(props.record.paymentCapture);
  const targetDate =
    getDate(paymentDate) > 15 ? addMonths(paymentDate, 1) : paymentDate;
  const dateLabel = format(targetDate, 'MMMM - yy');

  return (
    <FunctionField
      {...props}
      label="Cycle Label"
      render={(props: any) => {
        return (
          <Chip
            className={classes[props.productPlanSku]}
            avatar={
              <Avatar alt={props.country} src={getFlagPath(props.country)} />
            }
            key={props.country}
            label={` ${
              props.productPlanSku !== 'GOODIEBOX' ? props.productPlanSku : ''
            } ${dateLabel} `}
          />
        );
      }}
    />
  );
};

export default CycleLabel;
