import * as React from 'react';
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  List,
  Datagrid,
  TextField,
  ImageInput,
  ImageField,
  TextInput,
} from 'react-admin';

// Since we do not display the list of Strapi products to avoid heavy payloads, we display empty Datagrid
export const ProductsListEmpty = (props: any) => {
  return (
    <List {...props}>
      <Datagrid></Datagrid>
    </List>
  );
};

export const ProductsCreate = (props: any) => {
  return (
    <Create title="Upload a file" {...props}>
      <SimpleForm submitOnEnter={false}>
        <FileInput
          source="files"
          label="Related files"
          accept=".xlsx,.xls"
          name="files"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export const ProductsList = (props: any) => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="photo_url" />
      </Datagrid>
    </List>
  );
};

const maxSizeForFileUpload = 5000000;

export const ProductsImagesBulkUpload = (props: any) => {
  return (
    <Create title="Upload images" {...props}>
      <SimpleForm submitOnEnter={false} redirect="/upload_missing_images">
        <ImageInput
          source="name"
          label="Update Pictures"
          accept="image/*"
          multiple={true}
          maxSize={maxSizeForFileUpload}
          labelMultiple="Upload one or more images. Max size 5MB"
        >
          <ImageField source="id" title="name" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export const ProductsUpdate = (props: any) => (
  <Create {...props}>
    <SimpleForm submitOnEnter={false} redirect="/upload_missing_images">
      <ImageInput
        source="name"
        label="Update Picture"
        accept="image/*"
        multiple={true}
        maxSize={maxSizeForFileUpload}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput disabled source="id" value={props.id} />
    </SimpleForm>
  </Create>
);
