import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  List,
  Datagrid,
  TextField,
  NumberField,
} from 'react-admin';

export const BoxSplitScopeCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="name" label="Name" />
        <TextInput source="description" label="Description" />
      </SimpleForm>
    </Create>
  );
};

export const BoxSplitScopeList = (props: any) => (
  <List {...props} perPage={50}>
    <Datagrid rowClick="edit">
      <NumberField source="id" />
      <TextField source="name" />
      <TextField source="description" />
    </Datagrid>
  </List>
);
