import { eachDayOfInterval, subDays, format } from 'date-fns/fp';
import * as React from 'react';
import { Datagrid, NumberField, List, TextField } from 'react-admin';

const datesRange = eachDayOfInterval({
  start: subDays(7, new Date()),
  end: subDays(1, new Date()),
});
const datesStrings = datesRange.map((day) => format('yyyy-MM-dd', day));
export const ShippedBoxes = (props: any) => (
  <List {...props} title="Shipped boxes summary">
    <Datagrid>
      <TextField source="sku" />
      <NumberField source="dailyCounts[0]" label={`${datesStrings[0]}`} />
      <NumberField source="dailyCounts[1]" label={`${datesStrings[1]}`} />
      <NumberField source="dailyCounts[2]" label={`${datesStrings[2]}`} />
      <NumberField source="dailyCounts[3]" label={`${datesStrings[3]}`} />
      <NumberField source="dailyCounts[4]" label={`${datesStrings[4]}`} />
      <NumberField source="dailyCounts[5]" label={`${datesStrings[5]}`} />
      <NumberField source="dailyCounts[6]" label={`${datesStrings[6]}`} />
      <NumberField source="total" label="Total" />
    </Datagrid>
  </List>
);
