import * as React from 'react';
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  Datagrid,
  TextField,
  List,
  Filter,
  TextInput,
} from 'react-admin';

export const AdhocWowAddonsCreate = (props: any) => {
  const redirect = '/adhoc_wow_addons';
  return (
    <Create {...props}>
      <SimpleForm submitOnEnter={false} redirect={redirect}>
        <FileInput
          source="files"
          label="Related files"
          accept=".xlsx,.xls"
          name="files"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

const FilterCycle = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search by cycle ref" source="cycleRef" alwaysOn />
      <TextInput label="Search by member ID" source="memberId" alwaysOn />
    </Filter>
  );
};

export const AdhocWowAddonsList = (props: any) => {
  return (
    <List {...props} filters={<FilterCycle />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="memberId" />
        <TextField source="cycleRef" />
        <TextField source="wowProducts" />
      </Datagrid>
    </List>
  );
};
