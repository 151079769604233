import * as React from 'react';
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
} from 'react-admin';

export const UploadRulesCreate = (props: any) => {
  const redirect = '/upload_rules';
  return (
    <Create {...props}>
      <SimpleForm submitOnEnter={false} redirect={redirect}>
        <FileInput
          source="files"
          label="Related files"
          accept=".xlsx,.xls"
          name="files"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

const RulesFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search by Cycle Ref" source="cycleRef" alwaysOn />
      <TextInput label="Search by Box sku" source="boxSku" alwaysOn />
      <TextInput label="Search by Scope" source="scope" alwaysOn />
    </Filter>
  );
};

export const UploadRulesList = (props: any) => {
  return (
    <List {...props} perPage={50} filters={<RulesFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="cycleId" />
        <TextField source="cycleRef" />
        <TextField source="boxSku" />
        <TextField source="priority" />
        <TextField source="scope" />
      </Datagrid>
    </List>
  );
};
