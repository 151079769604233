import * as React from 'react';
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  List,
  Datagrid,
} from 'react-admin';

export const StrapiAddonsCreate = (props: any) => {
  const redirect = '/monthly_addons';
  return (
    <Create {...props}>
      <SimpleForm submitOnEnter={false} redirect={redirect}>
        <FileInput
          source="files"
          label="Related files"
          accept=".xlsx,.xls"
          name="files"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

// Only shows these fields at the moment
export const StrapiAddonsList = (props: any) => {
  return (
    <List {...props}>
      <Datagrid></Datagrid>
    </List>
  );
};
