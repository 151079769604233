import { Identifier } from 'ra-core';
import {
  CountryCode,
  CurrencyCode,
} from '@by-goodiebox/subscription-platform-entities';
import { format } from 'date-fns/fp';

type Cycle = any;
type IncompleteCycle = any;
type Rule = any;
type Note = any;
type BasicRuleEntryScope = any;

type BasicRuleEntry = {
  id: number;
  cycleId: number;
  cycleRef: string;
  boxSku: string;
  priority: number;
  scope: string;
};

type FileInput = {
  rawFile: File;
  src: string;
  title: string;
};

type AdhocOrder = {
  id: number;
  created_at: Date;
  sku: string;
  order_id: string;
  name: string;
  email: string;
  address1: string;
  address2: string | null;
  zipcode: string;
  city: string;
  country: CountryCode;
  telephone: string;
  submittedAt: Date | null;
  acceptedAt: Date | null;
  cancelledAt: Date | null;
  packedAt: Date | null;
};

export interface WowProduct {
  rowId: number;
  productSku: string;
  price: number;
  currency: CurrencyCode;
}
export interface MemberWowAddon {
  memberId: string;
  cycleRef: string;
  wowProducts: WowProduct[];
}

interface Box {
  id: string; // boxSku
  boxSku: string;
  total: number;
  available: number;
}

interface StrapiBoxCorrectSyntax {
  id: number;
  SKU: string;
  month: number;
  year: number;
  box_type: string;
  products: { id: number; name: string }[];
  consumables: { id: number; name: string }[];
  theme: { id: number; name: string };
  site: { id: number; url: string };
  photo_url: string;
}

interface StrapiBoxFormSyntax {
  id: number;
  SKU: string;
  month: number;
  year: number;
  box_type: string;
  products: string;
  consumables: string;
  theme_name: string;
  site_url: string;
  photo_url: string;
}

interface DryrunResult {
  id: number;
  flow: string;
  cycleRef: string;
  sku: string | null;
  count: number | null;
  error: string | null;
}

export interface StrapiScopeUploadResult {
  message: string;
  successResults: string[];
  errors: string[];
}

interface DryrunOptions {
  cycle: string;
  category: 'all' | 'bundled' | 'single';
}

type ShipmentSummary = Partial<Record<CountryCode, number>>;

type BoxQuantities = { [boxSku: string]: Omit<Box, 'boxSku' | 'id'> };

export default class ApiClient {
  apiHost: string;

  constructor(apiHost: string) {
    this.apiHost = apiHost;
  }

  async fetchShipmentSummary(date: Date): Promise<ShipmentSummary> {
    const dateStr = format('yyyy-MM-dd', date);
    const endpoint = `/manifest/daily/${dateStr}?summary=byCountry`;
    const method = 'GET';
    const result = await this.callApi<ShipmentSummary>({ endpoint, method });
    return result;
  }

  async fetchBoxShippedSummary(date: Date): Promise<Record<string, number>> {
    const dateStr = format('yyyy-MM-dd', date);
    const endpoint = `/manifest/daily/${dateStr}?summary=bySku`;
    const method = 'GET';
    const result = await this.callApi<Record<string, number>>({
      endpoint,
      method,
    });
    return result;
  }

  async fetchIncompleteCycles(): Promise<IncompleteCycle[]> {
    const endpoint = '/cycle/incomplete';
    const method = 'GET';
    const result = await this.callApi<IncompleteCycle[]>({ endpoint, method });
    return result.map((cycle, id) => ({ ...cycle, id }));
  }

  async fetchCycles(): Promise<Cycle[]> {
    const endpoint = `/cycle/all?from=${new Date()}`;
    const method = 'GET';
    return this.callApi<Cycle[]>({ endpoint, method });
  }

  async fetchCycle(id: Identifier): Promise<Cycle> {
    const endpoint = `/cycle/${id}`;
    const method = 'GET';
    const data = await this.callApi<Cycle>({ endpoint, method });
    return data;
  }

  async createCycle(data: Cycle): Promise<void> {
    const endpoint = '/cycle';
    const method = 'POST';
    const body = {
      incompleteCycle: {
        country: data.country,
        productPlanSku: data.productPlanSku,
        paymentCapture: data.paymentCapture,
      },
      dates: {
        manifestCreation: data.manifestCreation,
        boxRelease: data.boxRelease,
      },
    };
    await this.callApi<void>({ endpoint, method, body });
  }

  async updateCycle(
    id: Identifier,
    body: { manifestCreation: Date; boxRelease: Date },
  ): Promise<void> {
    const endpoint = `/cycle/${id}`;
    const method = 'PUT';
    try {
      await this.callApi<any>({
        endpoint,
        method,
        body,
        transformer: async () => undefined,
      });
    } catch (error) {
      throw new Error(`Error updating cycle with ID ${id}: ${error}`);
    }
  }

  async fetchRules(cycleId: Identifier): Promise<Rule[]> {
    const response = await this.callApi<Rule[]>({
      endpoint: `/cycle/${cycleId}/rule/all`,
      method: 'GET',
    });
    return response.map((rule) => ({ ...rule, cycleId }));
  }

  async createRule({ cycleId, boxSku, priority, scope }: Rule): Promise<void> {
    const endpoint = `/cycle/${cycleId}/rule`;
    const method = 'POST';
    const body = { boxSku, priority, scope };
    await this.callApi<void>({
      endpoint,
      method,
      body,
      transformer: async () => undefined,
    });
  }

  async deleteRule(cycleId: number, ruleId: Rule): Promise<void> {
    await this.callApi<void>({
      endpoint: `/cycle/${cycleId}/rule`,
      method: 'DELETE',
      body: { ruleId },
      transformer: async () => undefined,
    });
  }

  async fetchNotes(cycleId: Identifier): Promise<Note[]> {
    const response = await this.callApi<Note[]>({
      endpoint: `/cycle/${cycleId}/note/all`,
      method: 'GET',
    });
    return response;
  }

  async createNote({ cycleId, content }: Note): Promise<void> {
    await this.callApi<void>({
      endpoint: `/cycle/${cycleId}/note`,
      method: 'POST',
      body: { content },
      transformer: async () => undefined,
    });
  }

  async deleteNote(noteId: Note): Promise<void> {
    await this.callApi<void>({
      endpoint: `/note/${noteId}/`,
      method: 'DELETE',
      transformer: async () => undefined,
    });
  }

  async fetchBasicRuleEntryScopes(): Promise<BasicRuleEntryScope> {
    return await this.callApi<void>({
      endpoint: `/cycle/basic-rule-entry-scope/all`,
      method: 'GET',
    });
  }

  async addBasicRuleEntryScope({
    name,
    description,
  }: BasicRuleEntryScope): Promise<void> {
    await this.callApi<void>({
      endpoint: `/cycle/basic-rule-entry-scope`,
      method: 'POST',
      body: { name, description },
    });
  }

  async fetchBoxes(): Promise<Box[]> {
    const response = await this.callApi<BoxQuantities>({
      endpoint: `/boxes`,
      method: 'GET',
    });
    const boxes = Object.keys(response).map((boxSku) => ({
      ...response[boxSku],
      boxSku,
      id: boxSku,
    }));
    return boxes;
  }

  async createBox({ boxSku, available }: Box): Promise<void> {
    await this.callApi<void>({
      endpoint: `/boxes/${boxSku}`,
      method: 'PUT',
      body: { available },
      transformer: async () => undefined,
    });
  }
  async boxesUpload(title: string, rawFile: File): Promise<void> {
    const formData = new FormData();
    formData.append('boxesUpload', rawFile, title);
    await this.uploadFile({
      endpoint: `/boxes/file-upload`,
      body: formData,
    });
  }

  async deleteBox(boxId: any): Promise<void> {
    await this.callApi<void>({
      endpoint: `/boxes`,
      method: 'DELETE',
      body: { boxSku: boxId },
      transformer: async () => undefined,
    });
  }

  async fetchBoxSkus(): Promise<{ id: string; boxSku: string }[]> {
    const response = await this.callApi<string[]>({
      endpoint: `/box-skus`,
      method: 'GET',
    });
    const skus = response.map((k) => ({ id: k, boxSku: k }));
    return skus;
  }

  async bulkUploadAdhocOrders(fileInput: any): Promise<string> {
    let formData = new FormData();
    formData.append('file', fileInput.files.rawFile, fileInput.files.title);

    const result = await this.uploadFile({
      endpoint: `/shipment/bulk-upload-shipment`,
      body: formData,
    });
    return result;
  }

  async uploadWowAddons(fileInput: any): Promise<string> {
    let formData = new FormData();
    formData.append('file', fileInput.files.rawFile, fileInput.files.title);

    const result = await this.uploadFile({
      endpoint: `/wow-addons/upload`,
      body: formData,
    });
    return result;
  }

  async bulkUploadRules(fileInput: any): Promise<string> {
    const formData = new FormData();
    formData.append(
      'sampleFile',
      fileInput.files.rawFile,
      fileInput.files.title,
    );

    const result = await this.uploadFile({
      endpoint: `/cycle/bulk-upload-rules`,
      body: formData,
    });
    return result;
  }

  async bulkUploadStrapiBoxes(fileInput: any): Promise<string> {
    const formData = new FormData();
    formData.append(
      'sampleFile',
      fileInput.files.rawFile,
      fileInput.files.title,
    );

    const result = await this.uploadFile({
      endpoint: `/strapi-boxes/bulk-upload-strapi-boxes`,
      body: formData,
    });
    return result;
  }
  async uploadMonthlyAddons(fileInput: any): Promise<string> {
    const formData = new FormData();
    formData.append(
      'monthlyAddons',
      fileInput.files.rawFile,
      fileInput.files.title,
    );

    const result = await this.uploadFile({
      endpoint: `/monthly-addons/upload`,
      body: formData,
    });
    return result;
  }

  async fetchBasicRuleEntries(limit: number): Promise<BasicRuleEntry[]> {
    const response = await this.callApi({
      endpoint: `/cycle/basic-rule-entries/${limit}`,
      method: 'GET',
    });
    return response as BasicRuleEntry[];
  }

  async fetchAdhocOrders(limit: number): Promise<AdhocOrder[]> {
    const response = await this.callApi({
      endpoint: `/shipment/adhoc-orders?limit=${limit}`,
      method: 'GET',
    });
    return response as AdhocOrder[];
  }

  async fetchAdhocAddons(cycleRef: string): Promise<MemberWowAddon[]> {
    const response = await this.callApi({
      endpoint: `/wow-addons/${cycleRef}`,
      method: 'GET',
    });
    return response as MemberWowAddon[];
  }

  async deleteAdhocOrder(id: Identifier): Promise<void> {
    await this.callApi<void>({
      endpoint: `/shipment/adhoc-orders`,
      method: 'DELETE',
      body: { ids: [id] },
      transformer: async () => undefined,
    });
  }

  async deleteManyAdhocOrder(ids: Identifier[]): Promise<void> {
    await this.callApi<void>({
      endpoint: `/shipment/adhoc-orders`,
      method: 'DELETE',
      body: { ids },
      transformer: async () => undefined,
    });
  }

  async deleteManyAddons(ids: Identifier[]): Promise<void> {
    await this.callApi<void>({
      endpoint: `/wow-addons`,
      method: 'DELETE',
      body: { ids },
      transformer: async () => undefined,
    });
  }

  async fetchDryrunResults(): Promise<DryrunResult[]> {
    const response = await this.callApi({
      endpoint: `/dryrun-results`,
      method: 'GET',
    });
    return response as DryrunResult[];
  }

  async createDryrun({ cycle, category }: DryrunOptions): Promise<any> {
    const endpoint = `/manifest/dry-run/${cycle}?category=${category}`;
    const method = 'GET';
    const response = await this.callApi<void>({
      endpoint,
      method,
    });
    return response;
  }

  /** STRAPI PRODUCTS */

  async createStrapiProducts(title: string, rawFile: File): Promise<void> {
    const formData = new FormData();
    formData.append('strapiProducts', rawFile, title);
    await this.uploadFile({
      endpoint: `/products/file-upload`,
      body: formData,
    });
  }

  async fetchStrapiProducts(): Promise<any[]> {
    const endpoint = `/products`;
    const method = 'GET';
    return this.callApi<any>({ endpoint, method });
  }

  async uploadImages(fileInput: { name: FileInput[] }): Promise<void> {
    const formData = new FormData();

    fileInput.name.forEach((item: FileInput) => {
      formData.append('image', item.rawFile, item.title);
    });

    await this.uploadFile({
      endpoint: `/products/images-upload`,
      body: formData,
    });
  }

  async downloadProducts(): Promise<void> {
    await this.downloadFile({ endpoint: `/products/download` });
  }

  async syncStrapiBoxes(): Promise<void> {
    await fetch(
      'https://googleton8n.bygoodiebox.com/webhook/6e0aa4fa-53fe-4750-86d9-bd3872ba9098/',
      {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          Authorization: `${process.env.REACT_APP_N8N_KEY}`,
          Accept: '*/*',
        },
      },
    );
  }

  async uploadStrapiScopes({
    month,
    year,
  }: any): Promise<StrapiScopeUploadResult> {
    const endpoint = `/cycle/bulk-upload-rules/${year}/${month}`;
    const method = 'GET';
    const response = await this.callApi<StrapiScopeUploadResult>({
      endpoint,
      method,
    });
    return response;
  }

  private async callApi<T>({
    endpoint,
    method,
    body,
    transformer = (response) => response.json(),
  }: {
    endpoint: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    body?: unknown;
    transformer?: (response: Response) => Promise<T>;
  }): Promise<T> {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('Missing auth token');
    }

    const response = await fetch(`${this.apiHost}${endpoint}`, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      const { error } = await response.json().catch(() => ({
        error: undefined,
      }));

      const errorMessage = {
        status: response.status,
        message: `Error calling the cycle config API: ${error}`,
        error,
      };
      throw errorMessage;
    }

    return transformer(response);
  }

  private async uploadFile({
    endpoint,
    body,
  }: {
    endpoint: string;
    body: FormData;
  }): Promise<string> {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('Missing auth token');
    }

    let requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    };

    const response = await fetch(`${this.apiHost}${endpoint}`, requestOptions);
    if (!response.ok) {
      const { error } = await response.json().catch(() => ({
        error: undefined,
      }));

      const errorMessage = {
        status: response.status,
        message: `Error uploading file: ${error}`,
        error,
      };
      throw errorMessage;
    }
    return 'File upload successful!';
  }

  private async downloadFile({
    endpoint,
  }: {
    endpoint: string;
  }): Promise<void> {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('Missing auth token');
    }

    let requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'blob',
      },
      responseType: 'arraybuffer',
    };

    const response = await fetch(`${this.apiHost}${endpoint}`, requestOptions);
    if (!response.ok) {
      const { error } = await response.json().catch(() => ({
        error: undefined,
      }));

      const errorMessage = {
        status: response.status,
        message: `Error downloading file: ${error}`,
        error,
      };
      throw errorMessage;
    }

    // This downloads the file automatically
    const outputFilename = `${Date.now()}.xls`;
    const url = URL.createObjectURL(new Blob([await response.arrayBuffer()]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  }
}
