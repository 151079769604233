import { AuthProvider } from 'ra-core';

export default class CycleConfigAuthProvider implements AuthProvider {
  async login(params: any): Promise<any> {
    throw new Error('Not yet implemented');
  }

  async logout(params: any): Promise<void | string> {
    localStorage.removeItem('auth_token');
  }

  async checkAuth(params: any): Promise<void> {
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      throw new Error('Missing auth token');
    }
  }

  async checkError(error: any): Promise<void> {
    console.log('Checking error', error.status);
    const status = error.status;
    if (status === 401 || status === 403) {
      console.log('Removing token');
      localStorage.removeItem('auth_token');
      throw new Error();
    }
  }

  async getPermissions(params: any): Promise<any> {
    return ['user'];
  }
}
