import * as React from 'react';
import { parse } from 'query-string';
import { Create, SimpleForm, TextInput } from 'react-admin';

export const NoteCreate = (props: any) => {
  const { cycle_id: cycleIdString } = parse(props.location.search);

  const cycleId =
    cycleIdString && !Array.isArray(cycleIdString)
      ? parseInt(cycleIdString, 10)
      : '';
  const redirect = cycleId ? `/cycle/${cycleId}` : '/cycle';

  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ cycleId }} redirect={redirect}>
        <TextInput disabled source="id" />
        <TextInput
          multiline
          rowsMax={15}
          fullWidth
          source="content"
          label="Note"
        />
      </SimpleForm>
    </Create>
  );
};
