import * as React from 'react';
import {
  AutocompleteInput,
  useQuery,
  Error,
  Create,
  Edit,
  Datagrid,
  SimpleForm,
  TextInput,
  NumberInput,
  TextField,
  List,
  NumberField,
  Filter,
  FileInput,
  FileField,
} from 'react-admin';

const BoxSkuSearchInput = (props: any) => {
  const { data, error }: any = useQuery({
    type: 'getList',
    resource: 'box_sku',
    payload: {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'id', order: 'ASC' },
    },
  });
  if (error) return <Error />;
  if (!data) return null;

  return (
    <AutocompleteInput
      source="boxSku"
      allowEmpty
      choices={data}
      optionText="boxSku"
    />
  );
};

const required = (message = 'Required') => (value: any) => {
  return value || value === 0 ? undefined : message;
};

const number = (value: unknown) => {
  const isValid = isWholeNumber(value);
  if (!isValid) {
    return 'Must be a whole number';
  }
};

const isWholeNumber = (value: unknown): boolean => {
  return typeof value === 'number' && value % 1 === 0;
};

const availableStockValidation = (value: any) => {
  if (value < 0) {
    return 'The available Stock cannot be less than 0!';
  }
};

const validateAvailableStock = [required(), number, availableStockValidation];

export const BoxFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="search box-sku" source="boxSku" alwaysOn />
    </Filter>
  );
};

export const BoxList = (props: any) => (
  <List {...props} perPage={50} filters={<BoxFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="boxSku" />
      <NumberField source="qty_web" label="Qty Main account" />
      <NumberField source="qty_shop" />
      <NumberField source="total" />
      <NumberField source="available" />
    </Datagrid>
  </List>
);

export const BoxCreate = (props: any) => {
  return (
    <Create title="Upload a file" {...props}>
      <SimpleForm submitOnEnter={false}>
        <FileInput
          source="files"
          label="Related files"
          accept=".xlsx,.xls"
          name="files"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export const BoxEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Box SKU" disabled source="boxSku" />
      <NumberInput
        source="available"
        required
        validate={validateAvailableStock}
      />
    </SimpleForm>
  </Edit>
);
