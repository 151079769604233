import { parse } from 'query-string';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Button, Notification } from 'react-admin';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

export const Login = ({ theme }: any) => {
  useEffect(() => {
    const { token } = parse(window.location.search);
    if (token && typeof token === 'string') {
      localStorage.setItem('auth_token', token);
      window.location.replace('/');
      return;
    }
  }, []);
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);

  return (
    <ThemeProvider theme={muiTheme}>
      <Button
        href={`${
          process.env.REACT_APP_CYCLE_CONFIG_API_URI as string
        }/auth/google`}
        label="Login"
      ></Button>
      <Notification />
    </ThemeProvider>
  );
};
