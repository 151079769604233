import * as React from 'react';
import { parse } from 'query-string';
import {
  AutocompleteInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  useQuery,
} from 'react-admin';

export const RuleCreate = (props: any) => {
  const { data } = useQuery({
    type: 'getList',
    resource: 'box',
    payload: {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'id', order: 'ASC', filter: {} },
    },
  });

  const { data: scopes } = useQuery({
    type: 'getList',
    resource: 'box_split_scope',
    payload: {
      pagination: { page: 1, perPage: 5000 },
      sort: { field: 'id', order: 'DESC', filter: {} },
    },
  });

  const { cycle_id: cycleIdString } = parse(props.location.search);

  const cycleId =
    cycleIdString && !Array.isArray(cycleIdString)
      ? parseInt(cycleIdString, 10)
      : '';
  const redirect = cycleId ? `/cycle/${cycleId}/show` : '/cycle';

  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ cycleId }} redirect={redirect}>
        <TextInput disabled source="id" />
        <AutocompleteInput source="boxSku" choices={data} optionText="boxSku" />
        <NumberInput source="priority" />
        <AutocompleteInput source="scope" choices={scopes} label="Scope name" />
      </SimpleForm>
    </Create>
  );
};
