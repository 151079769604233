import * as React from 'react';
import { Datagrid, NumberField, List, DateField } from 'react-admin';

export const ShipmentSummary = (props: any) => (
  <List {...props} title="Shipments Summary">
    <Datagrid>
      <DateField source="date" />
      <NumberField source="summary.AT" label="Austria" />
      <NumberField source="summary.BE" label="Belgium" />
      <NumberField source="summary.DK" label="Denmark" />
      <NumberField source="summary.FI" label="Finland" />
      <NumberField source="summary.FR" label="France" />
      <NumberField source="summary.DE" label="Germany" />
      <NumberField source="summary.IT" label="Italy" />
      <NumberField source="summary.NL" label="Netherlands" />
      <NumberField source="summary.NO" label="Norway" />
      <NumberField source="summary.PL" label="Poland" />
      <NumberField source="summary.ES" label="Spain" />
      <NumberField source="summary.SE" label="Sweden" />
      <NumberField source="summary.CH" label="Switzerland" />
      <NumberField source="total" label="Total" />
    </Datagrid>
  </List>
);
