import * as React from 'react';
import {
  Create,
  Edit,
  Datagrid,
  SimpleForm,
  TextInput,
  DateInput,
  Button,
  NumberField,
  TextField,
  ReferenceManyField,
  Link,
  List,
  DateField,
  Filter,
  SelectInput,
  DeleteButton,
} from 'react-admin';
import CycleLabel from './CycleLabel';
import {
  validateBoxRelease,
  validateManifestCreation,
} from './IncompleteCycles';
import { subMonths, addMonths, format } from 'date-fns';
import { CustomBulkActionButtons } from './CustomBulkActionButtons';
import Typography from '@material-ui/core/Typography';

const AddRuleButton = ({ record }: any) => (
  <Button
    component={Link}
    to={{ pathname: '/rule/create', search: `?cycle_id=${record.id}` }}
    label="Add Rule"
  ></Button>
);

const AddNoteButton = ({ record }: any) => (
  <Button
    component={Link}
    to={{ pathname: '/note/create', search: `?cycle_id=${record.id}` }}
    label="Add Note"
  ></Button>
);

export const generateMonthsChoices = () => {
  const arrayOfMonthOptions = [...Array(18)].map((v, i) => {
    const startingMonth = subMonths(new Date(), 6);
    const currentMonth = addMonths(startingMonth, i);
    // cycleMonth is always the captureDate month + 1
    const cycleMonth = addMonths(startingMonth, i + 1);
    return {
      id: format(currentMonth, 'yyyy-MM'),
      name: format(cycleMonth, 'MMMM - yy'),
    };
  });
  return arrayOfMonthOptions;
};

export const CyclesFilter = (props: any) => {
  return (
    <Filter {...props}>
      <SelectInput
        label="Search by Month"
        source="cycleMonth"
        alwaysOn
        choices={generateMonthsChoices()}
      />
    </Filter>
  );
};

export const CycleList = (props: any) => {
  const [manifestCreationDate, setManifestCreationDate] = React.useState(
    new Date(),
  );
  const [boxReleaseDate, setBoxReleaseDate] = React.useState(new Date());
  const handleManifestCreationDateChange = (e: any) => {
    setManifestCreationDate(e.target.value);
  };

  const handleBoxReleaseDateChange = (e: any) => {
    setBoxReleaseDate(e.target.value);
  };

  return (
    <React.Fragment>
      <SimpleForm toolbar={null}>
        <Typography>
          To update Manifest Creation and Box Release Date
        </Typography>
        <Typography>- Select rows</Typography>
        <Typography>- Select both dates</Typography>
        <Typography>- Click on update All button</Typography>
        <DateInput
          label="Manifest creation"
          source="manifestCreation"
          value={manifestCreationDate}
          onChange={handleManifestCreationDateChange}
        />
        <DateInput
          label="Box release"
          source="boxRelease"
          value={boxReleaseDate}
          onChange={handleBoxReleaseDateChange}
        />
      </SimpleForm>
      <List
        {...props}
        perPage={50}
        filters={<CyclesFilter />}
        bulkActionButtons={
          <CustomBulkActionButtons
            selectedIds={props.selectedIds}
            manifestCreationDate={manifestCreationDate}
            boxReleaseDate={boxReleaseDate}
          />
        }
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <CycleLabel source="Cycle Label" {...props} />
          <TextField source="country" />
          <TextField source="productPlanSku" />
          <DateField source="paymentCapture" />
          <DateField source="manifestCreation" />
          <DateField source="boxRelease" />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

export const CycleEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="country" />
      <TextInput disabled source="productPlanSku" />
      <DateInput disabled source="paymentCapture" />
      <DateInput
        source="manifestCreation"
        required
        validate={validateManifestCreation}
      />
      <DateInput source="boxRelease" required validate={validateBoxRelease} />
      <ReferenceManyField
        addLabel={false}
        reference="rule"
        target="cycle_id"
        sort={{ field: 'priority', order: 'ASC' }}
      >
        <Datagrid>
          <TextField source="boxSku" />
          <NumberField source="availableQty" label="Available" />
          <NumberField source="priority" />
          <TextField source="scope" />
          <DeleteButton undoable={false} redirect={false} />
        </Datagrid>
      </ReferenceManyField>
      <AddRuleButton />

      <ReferenceManyField
        addLabel={false}
        reference="note"
        target="cycle_id"
        sort={{ field: 'timestamp', order: 'ASC' }}
      >
        <Datagrid>
          <TextField source="content" label="Note" />
          <DeleteButton undoable={false} redirect={false} />
        </Datagrid>
      </ReferenceManyField>
      <AddNoteButton />
    </SimpleForm>
  </Edit>
);

export const CycleCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="country" />
      <TextInput disabled source="productPlanSku" />
      <DateInput disabled source="paymentCapture" />
      <DateInput source="manifestCreation" />
      <DateInput source="boxRelease" />
    </SimpleForm>
  </Create>
);
