import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  List,
  Datagrid,
} from 'react-admin';

//display empty list because fuck react-admin
export const dryrunList = (props: any) => {
  return (
    <List {...props}>
      <Datagrid></Datagrid>
    </List>
  );
};

export const DryrunForm = (props: any) => {
  const CATEGORIES = [
    { id: 'all', name: 'all' },
    { id: 'bundled', name: 'bundled' },
    { id: 'single', name: 'single' },
  ];
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Cycle" source="cycle" />
        <SelectInput source="category" choices={CATEGORIES} label="Category" />
      </SimpleForm>
    </Create>
  );
};
