import * as React from 'react';
import { dataProvider } from './providers';

const styles = {
  backgroundColor: '#EA4C89',
  borderRadius: '8px',
  borderWidth: '0',
  color: 'white',
  cursor: 'pointer',
  display: 'inline-block',
  margin: '0',
  padding: '10px 12px',
  transition: 'all 200ms',
  verticalAlign: 'baseline',
  width: '200px',
};

export const Download = () => {
  const [placeHolderText, setPlaceholderText] = React.useState<string>();
  const download = () => {
    setPlaceholderText(`Downloading file, please wait...`);
    dataProvider.apiClient
      .downloadProducts()
      .then(() => {
        setPlaceholderText(undefined);
      })
      .catch((err) => {
        setPlaceholderText(JSON.stringify(err, null, 2));
      });
  };
  return (
    <>
      <button style={styles} onClick={download}>
        Download products
      </button>
      {placeHolderText && <h5>{placeHolderText}</h5>}
    </>
  );
};
