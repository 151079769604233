import React from 'react';
import { FC, useState } from 'react';
import {
  BulkActionsToolbar,
  BulkDeleteButton,
  DateInput,
  TextInput,
  useNotify,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { dataProvider } from './providers';
import Button from '@material-ui/core/Button';
interface CustomBulkActionButtonsProps {
  selectedIds: string[];
  manifestCreationDate: Date;
  boxReleaseDate: Date;
}

export const CustomBulkActionButtons: FC<CustomBulkActionButtonsProps> = ({
  selectedIds,
  manifestCreationDate,
  boxReleaseDate,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const handleUpdate = async () => {
    console.log(selectedIds);
    console.log(manifestCreationDate);
    console.log(boxReleaseDate);
    try {
      for (const id of selectedIds) {
        const body = {
          manifestCreation: manifestCreationDate,
          boxRelease: boxReleaseDate,
        };
        console.log('Updating with data:', body);
        await dataProvider.apiClient.updateCycle(id, body);
      }
      refresh();
    } catch (error) {
      notify('error updating check dates');
    }
  };

  return (
    <BulkActionsToolbar
      style={{
        marginBottom: '180px',
        display: 'flex',
        gap: '12px',
      }}
    >
      <Button variant="contained" color="secondary" onClick={handleUpdate}>
        Update All
      </Button>
    </BulkActionsToolbar>
  );
};
